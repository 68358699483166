
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'130px'">
        <el-form-item label="站点名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入站点名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="上级站点" prop="parentId">
          <el-select-tree
            size="large"
            :props="props"
            :options="treeData"
            v-model="formModel.parentId"
            style="width:300px"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input v-model="formModel.longitude" placeholder="请输入经度" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude">
          <el-input v-model="formModel.latitude" placeholder="请输入纬度" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="最大功率" prop="maxPower">
          <el-input v-model="formModel.maxPower" placeholder="请输入最大功率" style="width:300px">
            <template slot="append">千瓦（KW）</template>
          </el-input>
        </el-form-item>
        <el-form-item label="功率类型" prop="powerType">
          <el-select v-model="formModel.powerType" style="width:300px;">
            <el-option
              v-for="result in powerTypeList"
              :key="result.value"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="formModel.address" placeholder="请输入地址" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="实时功率" prop="realPower" v-if="formModel.powerType=='2'">
          <el-input v-model="formModel.realPower" :disabled="true" style="width:300px">
            <template slot="append">千瓦（KW）</template>
          </el-input>
        </el-form-item>
        <div v-if="formModel.powerType=='1'">
          <h3>分时段功率设置</h3>
          <el-table 
          class="compact-table"
          :data="formModel.chargingStationPowerList" >
              <el-table-column prop="rangeDate" label="日期时段" width="400">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'chargingStationPowerList.' + scope.$index + '.rangeDate'"
                    :rules="ruleValidate['rangeDate']"
                  >
                    <el-date-picker
                      size="mini"
                      v-model="scope.row.rangeDate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      >
                    </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="startTime" label="起始时间" width="150">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'chargingStationPowerList.' + scope.$index + '.startTime'"
                    :rules="ruleValidate['startTime']"
                  >
                    <el-time-select
                      style="width:100px"
                      size="mini"
                      v-model="scope.row.startTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00'
                      }">
                    </el-time-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="结束时间" width="150">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'chargingStationPowerList.' + scope.$index + '.endTime'"
                    :rules="ruleValidate['endTime']"
                  >
                    <el-time-select
                      style="width:100px"
                      size="mini"
                      v-model="scope.row.endTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00'
                      }">
                    </el-time-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="power" label="功率（KW）" >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'chargingStationPowerList.' + scope.$index + '.power'"
                    :rules="ruleValidate['power']"
                  >
                    <el-input-number placeholder="功率" :min="0" :step="0.1" v-model="scope.row.power"  size="mini">
                    </el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" fixed="right">
                <template slot-scope="{row}">
                  <el-form-item>
                    <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                    <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                  </el-form-item>
                </template>
              </el-table-column>

              <div slot="empty">
                <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
              </div>
          </el-table>
        </div>
      </el-form>
        <div v-if="formModel.powerType=='1'">
          <div class="el-table__empty-block"  v-if="formModel.chargingStationPowerList.length!=0">
            <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
          </div>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingStationApi from "@/api/base/chargingStation";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            name: [
                { required: true, message: "站点名称不能为空", trigger: "blur" }
            ],
            longitude: [
                { required: true, message: "经度不能为空", trigger: "blur" }
            ],
            latitude: [
                { required: true, message: "纬度不能为空", trigger: "blur" }
            ],
            maxPower: [
                { required: true, message: "最大功率不能为空", trigger: "blur" }
            ],
            powerType: [
                { required: true, message: "功率类型不能为空", trigger: "blur" }
            ],
            rangeDate: [
                { required: true, message: "日期时段不能为空", trigger: "blur" }
            ],
            startTime: [
                { required: true, message: "开始时间不能为空", trigger: "blur" }
            ],
            endTime: [
                { required: true, message: "结束时间不能为空", trigger: "blur" }
            ],
            address: [
                { required: true, message: "地址不能为空", trigger: "blur" }
            ],
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        name:"",
        parentId:"",
        longitude:"",
        latitude:"",
        maxPower:"",
        powerType:"",
        address:"",
        chargingStationPowerList: [],
        removeChargingStationPowerList: [],
      },
      powerTypeList:[
        {
          "name":"分时段设置功率",
          "value":1,
        },
        {
          "name":"实时功率",
          "value":2,
        },
      ],
      templateList: [],
      defaultPriceList:[],
      treeData:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            //alert(JSON.stringify(self.formModel.chargingStationPowerList))

            if (id == null || id.length == 0) {
              return chargingStationApi.add(self.formModel);
            } else {
              return chargingStationApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAdd() {
        this.formModel.chargingStationPowerList.push({
            rangeDate:[],
            startTime: "",
            endTime: "",
            power:"",
        });
    },
    handleInsert(row) {
        var chargingStationPowerList = this.formModel.chargingStationPowerList;
        var index = chargingStationPowerList.indexOf(row);

        if(index>=0){
            chargingStationPowerList.splice(index+1,0,{
              rangeDate:[],
              startTime: "",
              endTime: "",
              power:"",
            });
        }
    },
    handleDelete(row) {
        var chargingStationPowerList = this.formModel.chargingStationPowerList;

        chargingStationPowerList.splice(chargingStationPowerList.indexOf(row), 1);

        this.formModel.removeChargingStationPowerList.push(row);
    },
  },
  created() {
      chargingStationApi.treeList().then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return chargingStationApi.create();
      } else {
        return chargingStationApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data; 
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
.input-form-main{
    width: 300px;
}
.link-span{
    margin-right: 20px;
}
.el-form-item{
    margin-bottom: 25px;
}
.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.user-panel {
  margin: 10px auto;
}

.compact-table{
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>